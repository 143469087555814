<template>
  <router-view />
</template>

<script>
export default {
  name: "app",
  metaInfo() {
    return {
      title: "MoDeck - Automate After Effects Mogrts Online",
      meta: [
        {
          name: "description",
          content:
            "Bring your After Effects templates online and allow anyone to edit them and create powerful video automations with an easy to use, web-based editor.",
        },
        {
          property: "og:title",
          content: "MoDeck - After Effects Mogrts Automated",
        },
        { property: "og:site_name", content: "MoDeck" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
};
</script>

<style>
#silentbox-overlay__background {
  background: rgba(54, 69, 99, 0.8) !important;
  backdrop-filter: none !important;
}
#silentbox-overlay__close-button {
  right: 18px !important;
}
#silentbox-overlay__embed img,
#silentbox-overlay__embed iframe,
#silentbox-overlay__container,
#silentbox-overlay__embed {
  pointer-events: none;
}
#silentbox-overlay__embed iframe {
  pointer-events: all;
}
</style>
