import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueSilentbox from "vue-silentbox";
import { createMetaManager, plugin as metaPlugin } from "vue-meta";

import 'vue-silentbox/dist/style.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'
import '../src/assets/css/fontawesome-all.min.css'
import 'animate.css/animate.min.css'
import '../src/assets/scss/style.scss'

const metaManager = createMetaManager();

createApp(App)
  .use(router)
  .use(VueSilentbox)
  .use(metaManager)
  .use(metaPlugin)
  .mount("#app");