<template>
  <div class="accordion-faq">
    <div class="accordion" id="accordionOne">
      <div v-for="(faq, faqidx) in faqs" :key="faqidx" class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button @click="faq.show = !faq.show" class="accordion-button" type="button" :aria-expanded="faq.show.toString()">
            {{ faq.title }}
          </button>
        </h2>
        <transition name="fade">
          <div v-if="faq.show">
            <div class="accordion-body" v-html="faq.body"></div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accordion",
  data() {
    return {
      faqs: [
        {
          title: "How do I turn my After Effects project into an online template on MoDeck?",
          body: `Online templates on MoDeck are made entirely using <a style="color: #ee408b" href="https://helpx.adobe.com/after-effects/using/creating-motion-graphics-templates.html" target="_blank">mogrt files</a> exported from After Effects, simply add the properties you would like to make editable online into the Essential Graphics panel and export a mogrt into your MoDeck sync folder. See the <a style="color: #ee408b" href="https://modeck.io/docs#make-a-deck" target="_blank">docs</a> for more details.`,
          show: false,
        },
        {
          title: "How are the videos rendered?",
          body: `Renders are handled on your own hardware facilitated by the MoDeck desktop app, MoDeck uses a standalone application that you install on your machine, when a user on the online editor requests a render, this is communicated to the desktop app, which assembles the video and renders it, the video files are then uploaded to the cloud to be accessible by editors online. See the <a style="color: #ee408b" href="https://modeck.io/docs#rendering-edits" target="_blank">docs</a> for more details.`,
          show: false,
        },
        {
          title: "Can editors preview their changes online before rendering?",
          body: `Yes, as long as the MoDeck desktop app is currently running on the template owner's machine, editors will be able to see still frame previews of their changes on the online editor, when building your templates you can define which still frames are sent as previews using the <a style="color: #ee408b" href="https://modeck.io/docs#modk-commands" target="_blank">modk-previewFrame command</a>.`,
          show: false,
        },
        {
          title: "What properties can be made editable online?",
          body: "Any property that can be added to the Essential Graphics panel in After Effects can be made editable online, this includes text, sliders, colors, footage etc.",
          show: false,
        },
        {
          title: "What file formats can MoDeck render?",
          body: `By default MoDeck will render mp4 files, but MoDeck can be set to render any format that can be rendered from either After Effects or Media Encoder as well as gif files, through the render settings of the desktop app you are able to select which formats to render, you are also able to select your own Media Encoder preset and MoDeck will automatically launch Media Encoder in order to render with your preset.`,
          show: false,
        },
        {
          title: "Can I customize the MoDeck online editor?",
          body: `As an enterprise feature of MoDeck you are able to <a style="color: #ee408b" href="https://modeck.io/docs#white-labeling" target="_blank">customize the color scheme and logo</a> of the online editor, these customizations also apply to notification emails, giving your users an on-brand experience. If you would like to take your customization further you are also able to utilize the <a style="color: #ee408b" href="https://modeck.io/docs#modeckapi" target="_blank">MoDeck API</a> in order to fully integrate the template editing features of MoDeck into your own applications.`,
          show: false,
        },
        //{ title: "Is MoDeck similar to Dataclay? What is the difference?", body: ``, show: false }
      ],
    };
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;
  max-height: 230px;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  max-height: 0px;
}
</style>
