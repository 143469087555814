<template>
  <div class="main-container">
    <router-view></router-view>
    <!-- Navbar section -->
    <Header />

    <!-- hero section -->
    <Hero />

    <!-- intro start -->
    <div class="feature-icon-list-area section-space--mt_40" id="about">
      <div class="container">
        <div class="section-title-wrapper text-center section-space--mb_40">
          <h2 class="section-title font-weight--light section-space--mb_40">
            Make your After Effects mogrts automated and editable online, through a powerful, drag and drop, web-based editor.
          </h2>
          <div class="video-button-container video-popup mb-30">
            <div @click="opensilentbox('https://www.youtube.com/watch?v=JKWOJZWDnlc')" style="cursor: pointer" class="video-play">
              <i class="fa fa-play-circle"></i>
              <span class="video-text">INTRO VIDEO</span>
            </div>
          </div>
          <!--<div class="video-button-container video-popup mb-30">
              <div @click="opensilentbox('https://www.youtube.com/watch?v=FbJTpEtV7q4')" style="cursor: pointer" class="video-play">
                <i class="fa fa-play-circle"></i>
                <span class="video-text">OVERVIEW/TUTORIAL</span>
              </div>
          </div>-->
        </div>
      </div>
      <FeatureList />
    </div>

    <!-- steps start -->
    <div id="steps" class="payment-feature section-space--pt_90 section-space--pb_90 bg-gray">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title-wrapper text-center section-space--mb_60">
              <h2 class="section-title font-weight--light mb-15">How it works.</h2>
              <span class="section-text">
                For more in-depth details, check out the
                <a style="color: #ee408b" href="/docs">docs.</a>
              </span>
            </div>
          </div>
        </div>
        <PaymentFeature />
      </div>
    </div>
    <!-- steps end -->

    <div id="faq" class="faq-banner-area section-space--pt_90 section-space--pb_90 bg-gray">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title-wrapper text-center section-space--mb_60">
              <h2 class="section-title font-weight--light">Frequently Asked Questions</h2>
            </div>
            <div class="faq-wrapper">
              <Accordion />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- call to action component -->
    <CtaOne />

    <!-- pricing section -->
    <div class="pricing-table-area section-space--pt_90 section-space--pb_0" id="pricing">
      <div class="pricing-table-title-area position-relative">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="section-title-wrapper text-center section-space--mb_60">
                <h2 class="section-title font-weight--light mb-15">Get the full features of MoDeck.</h2>
                <span class="section-text">
                  Purchase a subscription from
                  <a style="color: #ee408b" href="https://aescripts.com/modeck/" target="_blank">aescripts.com/modeck</a>
                  then enter your serial key in your
                  <a style="color: #ee408b" href="https://app.modeck.io/settings?s=1" target="_blank">account settings</a>
                  to activate your subscription.</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pricing-table-content-area">
        <div class="container">
          <!-- pricing component -->
          <PricingOne />
        </div>
      </div>
    </div>

    <!-- footer section -->
    <FooterMain />
  </div>
</template>

<script>
import Header from "../components/Header";
import Hero from "../components/Hero";
import PricingOne from "../components/PricingOne";
import CtaOne from "../components/CtaOne";
import FooterMain from "../components/FooterMain";
import PaymentFeature from "../components/PaymentFeature";
import FeatureList from "../components/FeatureList";
import Accordion from "../components/Accordion";

export default {
  name: "app",
  components: {
    Header,
    Hero,
    FeatureList,
    PricingOne,
    PaymentFeature,
    CtaOne,
    FooterMain,
    Accordion,
  },
  methods: {
    opensilentbox(src) {
      this.$silentbox.open({ src });
    },
  },
};
</script>
