<template>
  <div class="feature-app-showcase section-space--pb_90">
    <transition name="fade">
      <div class="info-modal" v-if="infoModal.show">
        <div class="modal-container">
          <div @click.prevent="infoModal.show = false" class="modal-close">&times;</div>
          <h3 class="modal-title">{{ infoModal.title }}</h3>
          <div v-html="infoModal.body"></div>
        </div>
        <div @click.prevent="infoModal.show = false" class="modal-bg"></div>
      </div>
    </transition>
    <router-link to="/" class="back">&lt;</router-link>
    <div class="container_fl">
      <div class="row align-items-center" style="justify-content: center">
        <div class="col-lg-5">
          <div class="app-showcase-image__media" style="text-align: center">
            <img :src="thumbnail" class="img-fluid" alt="thumbnail" />
          </div>
        </div>
        <div class="col-lg-5">
          <div class="app-showcase__content">
            <div class="app-showcase__inner" style="padding: 60px">
              <h4 class="font-weight--light">{{ title }}</h4>
              <div class="sub-text section-space--mb_40 section-space--mt_40">
                {{ desc }}<br /><br />Learn more about MoDeck in the
                <a href="https://modeck.io/docs#desktop-app" target="_blank" style="color: #68c0ce">docs</a>.
              </div>
              <div v-if="getOS() === 'win'">
                <div class="btn-sowcase section-space--mb_20">
                  <a href="https://modeck-app.s3.amazonaws.com/MoDeck_Setup.exe" class="ht-btn ht-btn-md" style="background: #68c0ce"
                    ><i class="mr-3 fa fa-download" style="margin-right: 10px"></i>MoDeck for Windows</a
                  >
                </div>
                <div class="btn-sowcase section-space--mb_20">
                  <a
                    href="https://modeck-app.s3.amazonaws.com/MoDeck_Setup.dmg"
                    class="ht-btn ht-btn-md"
                    style="background: #ffffff; border-color: #364563; color: #364563"
                    ><i class="mr-3 fa fa-download" style="color: #364563; margin-right: 10px"></i>MoDeck for MacOS</a
                  >
                </div>
              </div>
              <div v-else-if="getOS() === 'macos'">
                <div class="btn-sowcase section-space--mb_20">
                  <a href="https://modeck-app.s3.amazonaws.com/MoDeck_Setup.dmg" class="ht-btn ht-btn-md" style="background: #68c0ce"
                    ><i class="mr-3 fa fa-download" style="margin-right: 10px"></i>MoDeck for MacOS</a
                  >
                </div>
                <div class="btn-sowcase section-space--mb_20">
                  <a
                    href="https://modeck-app.s3.amazonaws.com/MoDeck_Setup.exe"
                    class="ht-btn ht-btn-md"
                    style="background: #ffffff; border-color: #364563; color: #364563"
                    ><i class="mr-3 fa fa-download" style="color: #364563; margin-right: 10px"></i>MoDeck for Windows</a
                  >
                </div>
              </div>
              <div v-else>
                <div class="btn-sowcase section-space--mb_20">
                  <a href="https://modeck-app.s3.amazonaws.com/MoDeck_Setup.exe" class="ht-btn ht-btn-md" style="background: #68c0ce"
                    ><i class="mr-3 fa fa-download" style="margin-right: 10px"></i>MoDeck for Windows</a
                  >
                </div>
                <div class="btn-sowcase section-space--mb_20">
                  <a href="https://modeck-app.s3.amazonaws.com/MoDeck_Setup.dmg" class="ht-btn ht-btn-md" style="background: #68c0ce"
                    ><i class="mr-3 fa fa-download" style="margin-right: 10px"></i>MoDeck for MacOS</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--
    <div class="row" style="max-width: 1190px;margin: 80px auto 0 auto;">
      <div class="col-lg-12">
        <div class="section-title-wrapper text-center section-space--mb_60">
          <h2 class="section-title font-weight--light mb-15">Free After Effects tools & presets</h2>
        </div>
      </div>
      <div v-for="item in downloaditems" :key="item.id" class="col-lg-6 col-md-12 mb-25">
        <div class="ht-service-box--three download-block">
          <img :src="item.image" class="img-fluid" />
          <div class="service-box-wrap" style="padding: 20px">
            <div class="content" style="flex-grow: 1; margin-right: 10px;">
              <h5 class="heading font-weight--light">{{ item.heading }}</h5>
              <div class="service_text">{{ item.text }}</div>
            </div>
            <img :src="infoIcon" class="icon-btn" @click="infoModal = { title: item.heading, body: item.body, show: true }" />
            <img :src="dlIcon" class="icon-btn" style="margin-left: 10px" />
          </div>
        </div>
      </div>
    </div>
-->
  </div>
</template>

<script>
export default {
  name: "Download",
  data() {
    return {
      infoModal: { title: "Info", body: "Body", show: false },
      dlIcon: require("../assets/img/downloads/download.svg"),
      infoIcon: require("../assets/img/downloads/info.svg"),
      thumbnail: require("../assets/img/banners/aeroland-app-showcase-image-02.png"),
      title: "Download MoDeck Now for Free",
      desc: "The MoDeck desktop app syncs your After Effects mogrts to the MoDeck online editor, lists all the online edits made with your templates, and handles rendering edits and sharing them with the online user. Once installed, click the icon in your tray to open the app window, you can also double click the icon to open your sync folder.",
      downloaditems: [
        {
          image: require("../assets/img/downloads/parenttocorner.gif"),
          heading: "Power parent",
          text: "A dead simple yet powerful preset for pinning layers to the edges of other layers, create responsive layouts where layers reposition based on width or height of other text layers.",
          body: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
        },
        {
          image: require("../assets/img/downloads/textboxes.gif"),
          heading: "Word highlighter",
          text: "Place fully customizable boxes behind specific words/sentences within a single text layer, determined by curly brackets within the text.",
          body: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
        },
        {
          image: require("../assets/img/downloads/textboxes.gif"),
          heading: "Style your text using regex",
          text: "",
          body: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
        },
        {
          image: require("../assets/img/downloads/textboxes.gif"),
          heading: "Fancy subtitles",
          text: "",
          body: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
        },
      ],
    };
  },
  methods: {
    getOS() {
      var platform = window.navigator.platform,
        macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
        windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
        os = null;

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = "macos";
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = "win";
      }

      return os;
    },
  },
};
</script>

<style lang="scss" scoped>
.back {
  width: 40px;
  height: 40px;
  border: 1px solid #364563;
  font-size: 25px;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  margin: 30px;
}
.back:hover {
  border: none;
  background: #ee408b;
  color: #ffffff;
}

.icon-btn {
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-top: auto;
}

/* MODALS */
.info-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
}
.modal-bg {
  background-color: #27354c;
  opacity: 0.75;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
}
.modal-container {
  color: #27354c;
  padding: 30px 50px;
  background: #f4f7fc;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 160px);
  max-width: 860px;
  max-height: calc(100% - 100px);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 10;
  transition: all ease 0.2s;
  overflow-y: auto;
}
.modal-close {
  width: 35px;
  height: 35px;
  background-color: #68c0ce;
  cursor: pointer;
  border-radius: 0 0 0 10px;
  padding: 0 11px;
  position: absolute;
  right: 0;
  top: 0;
  color: #ffffff;
  font-size: 24px;
  line-height: 36px;
}
.modal-close:hover {
  filter: brightness(110%);
}
.info-modal p {
  font-size: 14px;
  font-weight: 300;
  margin: 20px 0 5px 0;
}
.info-modal h3 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}
.fade-enter-active,
.fade-leave-active {
  transition: ease opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>