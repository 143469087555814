<template>
    <div class="row pricing-table-one">
        <div class="col-md-6 col-xl-4 pricing-table" v-for="(pricing, index) in pricingTable" :key="index" :class="{'pricing-table--popular__bg': pricing.active}">
            <div class="pricing-table__inner" :class="{'bg-gradient': pricing.active}">
                <div class="pricing-table__feature-mark" v-if="pricing.isPopular">
                    <span>Popular Choice</span>
                </div>
                <div class="pricing-table__header">
                    <div class="pricing-table__image">
                        <img :src="pricing.thumb" class="img-fluid" :alt="pricing.alt">
                    </div>
                    <h5 class="pricing-table__title">{{ pricing.title }}</h5>
                    <div class="pricing-table__price-wrap">
                        <h6 class="currency">$</h6>
                        <h6 class="price">{{ pricing.value }}</h6>
                        <h6 class="period">/{{ pricing.duration }}</h6>
                    </div>
                </div>
                <div class="pricing-table__body">
                    <ul class="pricing-table__list">
                        <li v-for="(list, index) in pricing.price" :key="index">{{ list }}</li>
                    </ul>
                </div>
                <div class="pricing-table__footer">
                    <a :href="pricing.link" target="_blank" class="ht-btn ht-btn--outline ht-btn-default" :class="{'btn--white': pricing.active}">Get started</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PricingOne',
        data (){
            return {
                pricingTable: [
                    {
                        thumb: require('../assets/img/pricing/free.png'),
                        title: 'Free',
                        value: '0',
                        duration: 'mo',
                        alt: 'Pricing Thumb',
                        isPopular: false,
                        active: false,
                        link: "https://app.modeck.io/login?signup=1",
                        price: ['Edit videos online for free, forever!', 'Create templates free for 14 days', 'Create 2 templates', 'Invite 1 user per template', 'Up to 3 mogrts per template']
                    },
                    {
                        thumb: require('../assets/img/pricing/pro.png'),
                        title: 'Pro',
                        value: '19',
                        duration: 'mo',
                        alt: 'Pricing Thumb',
                        isPopular: false,
                        active: false,
                        link: "https://aescripts.com/modeck",
                        price: ['Create up to 5 templates', 'Invite 3 users per template', 'Up to 8 mogrts per template', 'Generate up to 30 videos from data',]
                    },
                    {
                        thumb: require('../assets/img/pricing/enterprise.png'),
                        title: 'Enterprise',
                        value: '79',
                        duration: 'mo',
                        alt: 'Pricing Thumb',
                        isPopular: false,
                        active: false,
                        link: "https://aescripts.com/modeck",
                        price: ['Unlimited templates', 'Unlimited editors per template', 'Unlimited mogrts per template', 'Generate unlimited videos from data', 'Customize color scheme and logo of editor', 'Share templates with a link', 'Embed the editor on your own site', 'Send notification emails from your own address', 'Fully customize notification emails when sent from own address', 'Unlimited API access']
                    }
                ]
            }
        }
    }
</script>