import { createRouter, createWebHistory } from 'vue-router';

import Home from '../pages/Home'
import Downloads from '../pages/Downloads'
import Docs from '../pages/Docs'
//import Templatization from '../pages/Templatization'
import Privacy from '../pages/PrivacyPolicy'
import TermsAndConditions from '../pages/TermsAndConditions'
import NotFound from '../pages/NotFound'

const routes = [
    {
        path:'/',
        name: 'Home',
        component: Home,
        meta: {
            title: 'MoDeck - Shareable templates created with After Effects'
        }
    },
    {
        path:'/downloads',
        name: 'Downloads',
        component: Downloads,
        meta: {
            title: 'MoDeck - Download the desktop app'
        }
    },
    /*{
      path:'/templatization',
      name: 'Templatization',
      component: Templatization,
      meta: {
          title: 'MoDeck - Templatization service'
      }
    },*/
    {
        path:'/docs',
        name: 'Docs',
        component: Docs,
        meta: {
            title: 'MoDeck - Documentation'
        }
    },
    {
        path:'/privacy-policy',
        name: 'Privacy Policy',
        component: Privacy,
        meta: {
            title: 'MoDeck - Privacy Policy'
        }
    },
    {
        path:'/terms-and-conditions',
        name: 'Terms And Conditions',
        component: TermsAndConditions,
        meta: {
            title: 'MoDeck - Terms & Conditions'
        }
    },
    {
        path:'/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
  
export default router;