<template>
    <div class="row">
        <div v-for="item in items" :key="item.id" class="col-lg-6 col-md-12 mb-25">
            <div class="ht-service-box--three">
                <div class="service-box-wrap">
                    <div class="image__media">
                        <img :src="item.icon" class="img-fluid" alt="Icon">
                    </div>
                    <div class="content">
                        <h5 class="heading font-weight--light">{{ item.heading }}</h5>
                        <div v-html="item.text" class="service_text"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PaymentFeature',
        data (){
            return {
                items: [
                    {
                        icon: require('../assets/img/icons/1.png'),
                        heading:'Sign up and download the desktop app', 
                        text:'<a style="color: rgb(238, 64, 139)" href="https://modeck.io/downloads" target="_blank">Download</a> and install the free desktop app, log in, and set your sync folder location.'
                    },
                    {
                        icon: require('../assets/img/icons/2.png'), 
                        heading:'Make an online template', 
                        text:'An online template is simply a collection of <a style="color: rgb(238, 64, 139)" href="https://blog.frame.io/2018/11/26/making-mogrts/" target="_blank">mogrt files.</a> Make a folder in your sync folder and add your mogrt files to it, you have now made a template that will be synced to the online editor. These mogrts can optionally be further extended through the use of <a style="color: rgb(238, 64, 139)" href="https://modeck.io/docs#preparing-mogrts" target="_blank">Modk commands.</a>'
                    },
                    {
                        icon: require('../assets/img/icons/3.png'), 
                        heading:'Invite others to use your template', 
                        text:'<a style="color: rgb(238, 64, 139)" href="https://modeck.io/docs#invite-others" target="_blank">Invite someone</a> by entering their email address in the template settings with the desktop app, they can now open this template in the online editor where they\'ll see all the templates mogrts, edit their properties, and arrange them in sequence to create videos.'
                    },
                    {
                        icon: require('../assets/img/icons/4.png'), 
                        heading:'Rendering edits', 
                        text:'<a style="color: rgb(238, 64, 139)" href="https://modeck.io/docs#rendering-edits" target="_blank">The desktop app runs in the system tray and will detect when an editor has requested a render,</a> this will automatically render the edit and send a download link to the user once complete. With the desktop app you can also choose to output edits to After Effects to make manual adjustments.'
                    },
                ]
            }
        }
    }
</script>
