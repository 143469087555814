<template>
  <header class="header-area navbar-light fixed-top" :class="{ 'is-sticky': scrolled }">
    <div class="container-fluid container-fluid--cp-150">
      <nav class="navbar navbar-expand-lg" id="navbar-example2" style="background: #fff">
        <router-link to="/">
          <img src="../assets/img/logo/logo-dark.png" alt="logo" />
        </router-link>

        <div class="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
          <ul class="nav main-menu" style="flex-shrink: 0">
            <li class="nav-item">
              <a class="nav-link" href="#steps">
                <span>HOW IT WORKS</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#faq">
                <span>FAQ</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#pricing">
                <span>PRICING</span>
              </a>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/docs">
                <span>DOCUMENTATION</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/downloads">
                <span>DOWNLOADS</span>
              </router-link>
            </li>
            <!--<li class="nav-item">
              <router-link class="nav-link" to="/templatization">
                <span>TEMPLATIZATION SERVICE</span>
              </router-link>
            </li>-->
          </ul>
        </div>
        <div class="header-config-wrapper btn-group" style="margin: 8px 0">
          <a href="https://app.modeck.io/login" style="white-space: nowrap"
            ><span style="padding: 16px 25px; display: inline-block; font-weight: 400">Log in</span></a
          >
          <a href="https://app.modeck.io/login?signup=1" class="ht-btn ht-btn--outline hire-btn d-xl-block" style="white-space: nowrap"
            >Sign up for free</a
          >
          <button
            class="navbar-toggler"
            style="margin-left: 10px"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      load: false,
      limitPosition: 200,
      scrolled: false,
      lastPosition: 500,
    };
  },
  mounted() {
    (function () {
      scrollTo();
    })();

    function scrollTo() {
      const links = document.querySelectorAll(".scroll > a");
      links.forEach((each) => (each.onclick = scrollAnchors));
    }

    function scrollAnchors(e, custom) {
      const distanceToTop = (el) => Math.floor(el.getBoundingClientRect().top);
      if (e) {
        e.preventDefault();
      }
      var targetID = custom ? custom : this.getAttribute("href");
      const targetAnchor = document.querySelector(targetID);
      if (!targetAnchor) return;
      const originalTop = distanceToTop(targetAnchor);
      window.scrollBy({ top: originalTop, left: 0, behavior: "smooth" });
      const checkIfDone = setInterval(function () {
        const atBottom = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 190;
        if (distanceToTop(targetAnchor) === 0 || atBottom) {
          targetAnchor.tabIndex = "-1";
          targetAnchor.focus();
          clearInterval(checkIfDone);
        }
      }, 800);
    }

    if (this.$route.hash) {
      scrollAnchors(null, this.$route.hash);
    }
  },
  methods: {
    // sticky menu script
    handleScroll() {
      if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
        this.scrolled = true;
        // move up!
      }
      if (this.lastPosition > window.scrollY) {
        this.scrolled = true;
        // move down
      }
      this.lastPosition = window.scrollY;
      this.scrolled = window.scrollY > 50;
    },

    // offcanvas searchbox
    toggleClass(addRemoveClass, className) {
      const el = document.querySelector("#search-overlay");
      if (addRemoveClass === "addClass") {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
