<template>
  <div>
    <div class="fourofour-wrapper">
      <h2>404</h2>
      <p>Page not found</p>
      <router-link :to="{path: '/'}">
        <button style="margin: 35px 10px">Go back</button>
      </router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: "NotFound"
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fourofour-wrapper {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 700px;
  text-align: center;
  padding: 0 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: black;
  height: 330px;
}
.fourofour-wrapper .deck-lock {
  width: 95px;
}
.fourofour-wrapper h2 {
  color: #303e5a;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 0px;
}
.fourofour-wrapper p {
  color: #303e5a;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-weight: 300;
  font-size: 16px;
  margin-top: 7px;
}
button {
  background-color: #68c0ce;
  border: none;
  color: white;
  height: 45px;
  padding: 0 42px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-weight: 300;
  border-radius: 6px;
  cursor: pointer;
}
button:hover {
  background-color: #78d3dd;
}
</style>
