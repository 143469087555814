<template>
  <div class="feature-icon-list">
    <div class="row">
      <div class="col-lg-6">
        <div class="feature-icon-list__media">
          <img src="../assets/img/banners/mockup.png" class="img-fluid" alt="" />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="feature-icon-list__content">
          <div class="feature-icon-list-wrapper">
            <div v-for="feature in features" :key="feature.id" class="single-feature-icon">
              <div class="single-feature-icon__media">
                <i class="fal feature-icon" :class="feature.icon"></i>
              </div>
              <div class="single-feature-icon__content">
                <h6 class="title">{{ feature.title }}</h6>
                <div v-html="feature.desc" class="text"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "featureList",
  data() {
    return {
      features: [
        {
          icon: "fa-users",
          title: "Give anyone the power to use your projects",
          desc:
            "Producers, team members, marketing managers, anyone can make videos by inputting data such as text, images and number values into your After Effects files through an easy online editor.",
        },
        {
          icon: "fa-file-video",
          title: "Putting mogrt files to work",
          desc:
            'Templates are built using standard <a style="color: rgb(238, 64, 139)" href="https://blog.frame.io/2018/11/26/making-mogrts/" target="_blank">mogrt files</a>, just install the desktop app and drop mogrt files in your sync folder and they\'ll instantly be online and ready for anyone you invite to input data and media.',
        },
        {
          icon: "fa-database",
          title: "Create data driven video sets",
          desc:
            '<a style="color: rgb(238, 64, 139)" href="https://modeck.io/docs#data-driven" target="_blank">Video versioning made effortless.</a> Need to create the same video in multiple languages? How about hundreds of videos, each containing different text and graph data? Through the online editor anyone can generate large sets of customized videos driven by spreadsheet data.',
        },
      ],
    };
  },
};
</script>


<style scoped>
.feature-icon {
  font-size: 34px;
  background: rgb(238, 64, 139);
  color: rgb(255, 255, 255);
  padding: 14px 0px;
  border-radius: 10px;
  margin-top: 24px;
  width: 62px;
  text-align: center;
  margin-left: 15px;
  display: inline-block;
}
</style>