<template>
  <div class="hero-software" id="home">
    <div class="container_f1">
      <div class="hero-item">
        <div class="hero-content">
          <h2 class="h1 hero-content-title">
            Video versioning &
            <br />collaboration reimagined.
          </h2>
          <h6 class="hero-content-subtitle">
            After Effects templates automated through an online editor.
            <br />You control the creative, allow your team to control the content.
          </h6>
          <a href="https://app.modeck.io/login?signup=1" class="btn btn--green">
            <span class="btn-icon ml-0 mr-2">
              <!--<i class="fa fa-arrow-alt-to-bottom"></i>-->
            </span> Get started for free
          </a>
          <a href="/docs" class="btn btn--outline" style="margin-left: 15px">
            <span class="btn-icon ml-0 mr-2">
              <!--<i class="fa fa-arrow-alt-to-bottom"></i>-->
            </span> Learn more
          </a>
        </div>
        <div class="hero-thumb pr-100">
          <lottie :options="defaultOptions" v-on:animCreated="handleAnimation"/>
          <!--<img src="../assets/img/splash.svg" alt="hero thumb" />-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Lottie from "./lottie.vue";
import * as animationData from "../assets/splash.json";
export default {
  name: "app",
  components: {
    lottie: Lottie
  },
  data() {
    return {
      defaultOptions: { animationData: animationData.default },
      animationSpeed: 1
    };
  },
  methods: {
    handleAnimation: function(anim) {
      this.anim = anim;
      this.anim.play();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/variabls.scss";
@import "../assets/scss/elements/hero-software.scss";
</style>
