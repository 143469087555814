<template>
  <div class="main-container">
    <router-view></router-view>

    <Download />

    <FooterMain />
  </div>
</template>

<script>
import Download from "../components/Download";
import FooterMain from "../components/FooterMain";

export default {
  name: "app",
  components: {
    Download,
    FooterMain,
  },
  metaInfo() {
    return {
      title: "MoDeck - Download the MoDeck desktop app",
    };
  },
};
</script>
